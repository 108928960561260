<template>
  <div class="content">
    <moreHeader :iconColor="1"></moreHeader>
    <h1>{{ data.title }}</h1>
    <div v-if="data.data">
      <div v-for="(item, i) in data.data" :key="i" class="Security">
        <span>{{ item.title }}</span
        >{{ item.text }}
      </div>
    </div>

    <p v-if="data.text">{{ data.text }}</p>
    <div v-if="data.content">
      <div v-for="(item, i) in data.content" :key="i">
        <div class="cloudTitle">{{ item.title }}</div>
        <span class="energyItem" v-for="(el, y) in item.data" :key="y">
          <span>{{ el.title }}</span>
          <div class="energyItems">
            <img :src="el.img" alt="" />
            <div>
              <div v-for="(els, n) in el.list" :key="n" class="energyItemText">
                {{ els }}
                <span></span>
              </div>
            </div>
          </div>
        </span>
      </div>
    </div>
    <img v-if="data.img" :src="data.img" alt="" />
    <div v-if="data.imgdata" class="safetyCity">
      <span v-for="(item, i) in data.imgdata" :key="i">
        <img :src="item.img" alt="" />
        <span>{{ item.text }}</span>
      </span>
    </div>
    <div class="more">更多场景：</div>

    <div v-if="id != 0" class="moreScene" @click="go(0)">云服务平台</div>
    <div v-if="id != 1" class="moreScene" @click="go(1)">能耗管理系统</div>
    <div v-if="id != 2" class="moreScene" @click="go(2)">立体综合安防系统</div>
    <div v-if="id != 3" class="moreScene" @click="go(3)">资产管理系统</div>
  </div>
</template>

<script>
import moreHeader from "../../../components/header.vue";
export default {
  components: { moreHeader },
  name: "",
  data() {
    return {
      id: null,
      data: {},
      data1: {
        title: "云服务平台",
        text: "在园区已建的有线、无线网络及园区数据中心的基础上，通过各子系统的数据整合，构建云办公、云商业、云管理等服务系统，提供完整的智慧园区应用。",
        img: require("../../../assets/assets/parkImg/img7.png"),
      },
      data2: {
        title: "能耗管理系统",
        text: "对电、水、气、热（冷）量等集中采集与分析，对企业端各类能耗进行细分和统计，展示各类能源的使用消耗情况，通过精细化的管理找出高耗能点或不合理的耗能习惯，有效节约能源，为用户进一步节能改造或设备升级提供准确的数据支撑。",
        content: [
          {
            title: "重点区域监测",
            data: [
              {
                title: "生产厂房",
                img: require("../../../assets/assets/parkImg/energyImg1.png"),
                list: [
                  "实时区域能耗检测",
                  "设备运行状况检测",
                  "实时掌握能耗情况",
                  "关键事件记录",
                ],
              },
              {
                title: "数据中心",
                img: require("../../../assets/assets/parkImg/energyImg2.png"),
                list: ["实现园区内重点区域用能情况监测", "识别非正常能耗"],
              },
            ],
          },
          {
            title: "重点负荷监测",
            data: [
              {
                title: "照明",
                img: require("../../../assets/assets/parkImg/energyImg3.png"),
                list: [
                  "实时负荷能耗监测",
                  "负荷动态能耗分析",
                  "负荷管理控制",
                  "有序用电管理",
                ],
              },
              {
                title: "空调暖通",
                img: require("../../../assets/assets/parkImg/energyImg4.png"),
                list: [
                  "实现园区内重点设备用能情况监测",
                  "高峰值期限电保电数据支持",
                ],
              },
            ],
          },
          {
            title: "能量预警系统",
            data: [
              {
                title: "预警",
                img: require("../../../assets/assets/parkImg/energyImg5.png"),
                list: [
                  "预制能耗预警值",
                  "自动发出预警信息",
                  "结合OA、eSpace办公系统短信、消息通知",
                ],
              },
              {
                title: "消息",
                img: require("../../../assets/assets/parkImg/energyImg6.png"),
                list: [
                  "及时了解能耗超标动态",
                  "及时调整用能",
                  "自动发出预警信息",
                ],
              },
            ],
          },
        ],
      },
      data3: {
        title: "立体综合安防系统",
        data: [
          {
            title: "安防重点：",
            text: "开放性园区在室外形成网格化、无视角、无缝连接的监控（周界）。",
          },
          {
            title: "人脸识别：",
            text: "在园区出入口、核心受控区等地点形成更精细化的人员管理功能。",
          },
        ],
        imgdata: [
          {
            text: "无线对讲",
            img: require("../../../assets/assets/parkImg/securityImg1.png"),
          },

          {
            text: "可视对讲",
            img: require("../../../assets/assets/parkImg/securityImg2.png"),
          },
          {
            text: "防盗报警",
            img: require("../../../assets/assets/parkImg/securityImg3.png"),
          },
          {
            text: "电子巡查",
            img: require("../../../assets/assets/parkImg/securityImg4.png"),
          },
          {
            text: "高空瞭望自动跟踪",
            img: require("../../../assets/assets/parkImg/securityImg5.png"),
          },
          {
            text: "周界防护",
            img: require("../../../assets/assets/parkImg/securityImg6.png"),
          },
          {
            text: "机器人巡检",
            img: require("../../../assets/assets/parkImg/securityImg7.png"),
          },
          {
            text: "人脸识别、图像识别",
            img: require("../../../assets/assets/parkImg/securityImg8.png"),
          },
        ],
      },
      data4: {
        title: "资产管理系统",
        text: "对日常管理中的资产信息进行实时监控、记录和自动更新，同时采集人员信息，真正实现“帐、卡、物、人”相符；将原来分散的资产管理信息集中起来，组合成为一个整体的资产管理信息平台，从而避免因人为因素造成的信息失真引起管理效能的下降。",
        img: require("../../../assets/assets/parkImg/img8.png"),
      },
    };
  },
  created() {
    this.id = this.$route.query.parkId;
    const i = this.$route.query.parkId;
    // eslint-disable-next-line eqeqeq
    if (i == 0) {
      this.data = this.data1;
    }
    // eslint-disable-next-line eqeqeq
    if (i == 1) {
      this.data = this.data2;
    }
    // eslint-disable-next-line eqeqeq
    if (i == 2) {
      this.data = this.data3;
    }
    // eslint-disable-next-line eqeqeq
    if (i == 3) {
      this.data = this.data4;
    }
    // eslint-disable-next-line eqeqeq
    if (i == 4) {
      this.data = this.data5;
    }
  },
  methods: {
    go(id) {
      this.$router.push({
        path: "/m_parkDetail",
        query: {
          parkId: id,
        },
      });
      this.id = id;
      if (id == 0) {
        this.data = this.data1;
      }
      // eslint-disable-next-line eqeqeq
      if (id == 1) {
        this.data = this.data2;
      }
      // eslint-disable-next-line eqeqeq
      if (id == 2) {
        this.data = this.data3;
      }
      // eslint-disable-next-line eqeqeq
      if (id == 3) {
        this.data = this.data4;
      }
      // eslint-disable-next-line eqeqeq
      if (id == 4) {
        this.data = this.data5;
      }
    },
  },
};
</script>

<style scoped lang="less">
.content {
  background-color: #fff;
  box-sizing: border-box;
  margin-top: -27px;
  box-sizing: border-box;
  padding-bottom: 10px;
  > h1 {
    padding-left: 10px;
    margin: 0;
    font-size: 20px;
    font-weight: normal;
    color: #333333;
    padding-top: 70px;
  }
  P {
    padding-left: 10px;
    text-indent: 2em;
    width: calc(100% - 20px);
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 300;
    line-height: 28px;
    color: #666666;
    margin: 20px 0;
  }
  > img {
    padding-left: 10px;
    display: inline-block;
    width: calc(100% - 20px);
    margin-bottom: 10px;
  }
  .more {
    margin: 0 20px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
    margin-top: 10px;
    border-top: 1px solid #d5d5d5;
    padding-top: 20px;
  }
  .moreScene {
    padding-left: 20px;
    width: 260px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #365ef1;
    margin-top: 10px;
  }
  .cloudTitle {
    width: calc(100% - 20px);
    height: 36px;
    line-height: 36px;
    text-align: center;
    font-size: 16px;
    color: #3651d8;
    margin: 10px;
    position: relative;
    background-color: rgba(214, 221, 255, 1);
  }
  .energyItem {
    position: relative;
    display: inline-block;
    width: calc(50% - 15px);
    height: 235px;
    margin-left: 10px;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
    .energyItems {
      display: flex;
      flex-direction: column;
      > img {
        display: inline-block;
        height: 114px;
      }
      > div {
        height: 120px;
        .energyItemText {
          position: relative;
          margin-top: 8px;
          font-size: 14px;
          font-weight: normal;
          color: #6b6b6b;
          box-sizing: border-box;
          padding: 0 18px;
          > span {
            position: absolute;
            left: 8px;
            top: 6px;
            display: inline-block;
            height: 6px;
            width: 6px;
            border-radius: 6px;
            background: #3651d8;
          }
        }
      }
    }

    > span {
      position: absolute;
      top: 40px;
      left: 20px;
      width: 112px;
      height: 40px;
      font-size: 28px;
      font-weight: normal;
      color: #ffffff;
    }
  }
  .safetyCity {
    margin-top: 18px;
    > span {
      display: inline-block;
      width: calc(50% - 15px);
      margin-left: 10px;
      img {
        display: inline-block;
        width: 100%;
        // margin-left: 20px;
      }
      span {
        width: 100%;
        display: inline-block;
        height: 18px;
        line-height: 18px;
        text-align: center;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        margin: 10px 0;
      }
    }
  }
  .Security {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 300;
    margin-top: 16px;
    color: #666666;
    width: calc(100% - 20px);
    margin-left: 10px;
    > span {
      color: rgb(39, 38, 38);
      font-weight: 400;
    }
  }
}
</style>
